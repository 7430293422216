.mainPoliciesContainer h2 {
  margin-top: 20px;
  font-family: "branding-semibold";
  color: "#576d96";
}

.mainPoliciesContainer h3 {
  margin-top: 20px;
  font-family: "branding-semibold";
  color: "#576d96";
}

.mainPoliciesContainer li {
  margin-left: 50px;
  padding: 10px;
}

.mainPoliciesContainer p {
  font-family: "branding-medium";
  color: "#576d96";
  text-align: justify;
}

.mainPoliciesContainer {
  background-color: #FFF;
  border-radius: 30px;
  padding: 30px;
}

.mainPolicies {
  padding: 30px;
}