* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  background-color: #f3f3f4;
}

form button {
  cursor: pointer;
}

html,
body {
  height: 100%;
  overflow-x: hidden;
  background-color: #f3f3f4;
}

* {
  font-family: open sans, Helvetica Neue, Helvetica, Arial, sans-serif;
}

label {
  font-family: open sans, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: bold;
}

#notifications::-webkit-scrollbar {
  display: none;
}
